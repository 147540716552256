<template>
  <b-card>

    <b-card-title class="font-weight-bolder">
      Demo Page - Toast
    </b-card-title>

    <b-row>
      <b-col cols="12"><h4>CallBell</h4></b-col>
      <b-col cols="12">
        <b-button @click="toastCallBell" class="mr-1 mb-1" variant="primary">Start</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12"><h4>GeoFencing</h4></b-col>
      <b-col cols="12">
        <b-button @click="toastGeoFencing" class="mr-1 mb-1" variant="primary">Start</b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, onUnmounted } from '@vue/composition-api'
import { watchWithFilter, debounceFilter, useCssVar } from '@vueuse/core'
import { srHub, srHubState, srStart, srStop, srUpdateFilter, srEventMap } from '@/libs/signalr-hub'
import { TagManager } from '@/libs/ez-utils'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import ToastRows from '@/components/ToastRows'
import common from '@/common'

export default {
  components: {},
  setup() {

    // let toastIdTemp = 0

    // global SignalR - CallBell
    let toastCallBell
    {
      let toastIds = []
      toastCallBell = function (data) {
        Vue.prototype.$toast({
          component: ToastRows,
          props: {
            variant: 'danger',
            title: common.getI18n('call_bell.call_bell_notification'),
            rows: [
              common.getI18n('call_bell.call_bell_name') + ': ' + 'Button-A01',
              common.getI18n('member.title') + ': ' + '陳大文' + '(' + '101-01' + ')',
              common.getI18n('common.time') + ': ' + Vue.prototype.$moment(new Date())
                .format('YYYY-MM-DD HH:mm:ss')
            ]
          },
          listeners: {
            confirm: () => {
              toastIds
                .forEach((id) => {
                  Vue.prototype.$toast.dismiss(id)
                  store.commit('app/DEC_CALL_BELL_TOAST_NUM')
                })
              toastIds = []
              const destination = 'callBellRecord'
              if (router.history.current.name !== destination) {
                router.push({ name: destination })
              }
            }
          }
        }, {
          timeout: false,
          draggable: false,
          id: store.state.app.toastIdTemp,
        })
        toastIds.push(store.state.app.toastIdTemp)
        store.commit('app/INC_TOAST_ID')
        store.commit('app/INC_CALL_BELL_TOAST_NUM')
      }
    }

    // global SignalR - GeoFencing
    let toastGeoFencing
    {
      let toastIds = []
      toastGeoFencing = function (data) {
        Vue.prototype.$toast({
          component: ToastRows,
          props: {
            variant: 'danger',
            title: common.getI18n('geo_fencing.notification'),
            rows: [
              common.getI18n('geo_fencing.antenna_name') + ': ' + '大門',
              common.getI18n('member.title') + ': ' + '陳大文' + '(' + '101-01' + ')',
              common.getI18n('common.time') + ': ' + Vue.prototype.$moment(new Date())
                .format('YYYY-MM-DD HH:mm:ss')
            ]
          },
          listeners: {
            confirm: () => {
              toastIds
                .forEach((id) => {
                  Vue.prototype.$toast.dismiss(id)
                  store.commit('app/DEC_GEO_FENCING_TOAST_NUM')
                })
              toastIds = []
              const destination = 'geoFencingRecord'
              if (router.history.current.name !== destination) {
                router.push({ name: destination })
              }
            }
          }
        }, {
          timeout: false,
          draggable: false,
          id: store.state.app.toastIdTemp,
        })
        toastIds.push(store.state.app.toastIdTemp)
        store.commit('app/INC_TOAST_ID')
        store.commit('app/INC_GEO_FENCING_TOAST_NUM')
      }
    }

    return {
      toastCallBell,
      toastGeoFencing
    }
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
